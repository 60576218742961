import cls from "./WebPage.module.scss";
import right from "../../assets/icon/right.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import MainScene from "../../component/MainScense/MainScene";
const Smart = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className={`${cls.root} ${i18n.language === "ru" ? cls.ru : ""}`}>
      <div className={cls.content}>
        <div className={cls.model}>
          {/* <MainScene position={[-0, -2, -.1]} canvaPosition={[.7, 0, 1.2]} headY={.3}/> */}
        </div>
        <div className={cls.title}>
          <p>{t("Services")}</p>
          <h1>
            {t("SMART")} <span>{t("CONTRACTS")}</span>
          </h1>
        </div>
        <div className={cls.blocks_description}>
          <div className={cls.flexBlock}>
            <div className={cls.block}>
              <p className={cls.title}>
                {t("Integration of Smart Contracts with Web Applications")}{" "}
              </p>
              <p className={cls.description}>
                {t(
                  "Connecting Smart Contracts to Websites: Integrating created smart contracts with web interfaces, allowing users to interact with blockchain functionalities through the website."
                )}
              </p>
            </div>
            <div className={cls.block}>
              <p className={cls.title}>{t("Audit and Security")}</p>
              <p className={cls.description}>
                {t(
                  "Smart Contract Audits: Conducting audits of smart contracts to identify and eliminate vulnerabilities. This includes manual and automated code analysis, penetration testing, and providing reports with recommendations for improving security."
                )}
              </p>
            </div>

            <div className={`${cls.block} ${cls.block3}`}>
              <p className={cls.title}>{t("Monitoring and Automation")}</p>
              <p className={cls.description}>
                {t(
                  "Creating Applications for Monitoring Transactions: Developing applications that listen to transactions of specific addresses or smart contracts in any EVM blockchain, as well as in the mempool, for analysis and notifications."
                )}
              </p>
            </div>
          </div>
          <div className={`${cls.flexBlock} ${cls.flexBlock1}`}>
            <div className={`${cls.block} ${cls.block7}`}>
              <p className={cls.title}>{t("Token Development")}</p>
              <p className={cls.description}>
                {t(
                  "Development of Tokens According to Popular Standards: Creating tokens that comply with standards like ERC-20, ERC-721, ERC-1155, BEP-20, BEP-721, etc., for various purposes such as governance, asset tokenization, NFT issuance, and more."
                )}
              </p>
            </div>
            <div className={`${cls.block} ${cls.block4}`}>
              <p className={cls.title}>
                {t("Creating a Minting Page for NFT Collections")}:
              </p>
              <p className={cls.description}>
                {t(
                  "Developing a page for minting NFTs, enabling users to easily create new tokens in your collection."
                )}
              </p>
            </div>
            <div className={`${cls.block} ${cls.block5}`}>
              <p className={cls.title}>
                {t(
                  "Connecting Websites to Wallets (Metamask, WalletConnect, etc.)"
                )}
                :
              </p>
              <p className={cls.description}>
                {t(
                  "Integrating your website with popular crypto wallets, allowing users to securely connect their wallets and interact with the blockchain."
                )}
              </p>
            </div>
            <div className={`${cls.block} ${cls.block6}`}>
              <p className={cls.title}>
                {t("Writing Scripts for Process Automation")}:
              </p>
              <p className={cls.description}>
                {t(
                  "Creating scripts to automate various tasks such as participating in retroactive airdrops and other activities requiring prompt responses."
                )}
              </p>
            </div>
          </div>
        </div>
        <div className={cls.mobileBlock}>
          <NavLink to={"#"}>
            {t("Check the project")}{" "}
            <img src={right} alt="right" width={"100%"} height={"auto"} />
          </NavLink>
        </div>
      </div>
    </div>
  );
};
export default Smart;
