import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // NavLink,
} from "react-router-dom";
import Footer from "./layout/Footer/Footer";
import Header from "./layout/Header/Header";
import WhatWeDo from "./pages/WhatWeDo/WhatWeDo";
import ContactUse from "./pages/ContactUse/ContactUse";
// import Swiper from "./component/Swiper";
import Services from "./pages/MobilleApp/Servises/Servises";
import { useTranslation } from "react-i18next";
import TeamPages from "./pages/TeamPages/TeamPages";

// function NavigationDot({ to, activePath, onClick }) {
//   const isActive = to === activePath;
//   return (
//     <NavLink
//       className={`dot ${isActive ? "active" : ""}`}
//       to={to}
//       onClick={onClick}
//     ></NavLink>
//   );
// }

function App() {
  const { i18n } = useTranslation();
  return (
    <Router>
      <div className={`App ${i18n.language}`}>
        <Header />
        <Routes>
          <Route exact path="/" element={<WhatWeDo />} />
          <Route path="/contact" element={<ContactUse />} />
          {/* <Route path="/Game" element={<Swiper />} /> */}
          <Route path="/services" element={<Services />} />
          <Route path="/team" element={<TeamPages />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
