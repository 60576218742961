import cls from "./WebPage.module.scss";
import right from "../../assets/icon/right.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import MainScene from "../../component/MainScense/MainScene";
const WebPage = () => {
  const { t,i18n } = useTranslation();
  return (
    <div className={`${cls.root} ${i18n.language === "ru" ? cls.ru : ""}`}>
      <div className={cls.content}>
        {/* <div className={cls.model}>
        <MainScene position={[-0, -2, -.1]} canvaPosition={[.7, 0, 1.2]} headY={.3}/>
      </div> */}
        <div className={cls.title}>
          <p>{t("Services")}</p>
          <h1>
            {t("WEB")} <span>{t("DEVELOPMENT")}</span>
          </h1>
        </div>
        <div className={cls.blocks_description}>
          <div className={cls.flexBlock}>
            <div className={cls.block}>
              <p className={cls.title}>{t("Training Simulation")}</p>
              <p className={cls.description}>
                {t("Hyper-realistic simulations using Unity3D/Unreal Engine, optimizing skill acquisition and assessment.")}
                
              </p>  
            </div>
            <div className={cls.block}>
              <p className={cls.title}>{t("Backend Development")}</p>
              <p className={cls.description}>
                {t("Seamless development leveraging Node.js, Django, and Ruby on Rails for robust server-side processing.")}                
              </p>
            </div>
            <div className={`${cls.block} ${cls.block3}`}>
              <p className={cls.title}>{t("Frontend Development")}</p>
              <p className={cls.description}>
                {t("Modern frameworks like React, Vue, and Angular for dynamic, responsive design.")}
              </p>
            </div>
          </div>
          <div className={`${cls.flexBlock} ${cls.flexBlock1}`}>
            <div className={`${cls.block} ${cls.block4}`}>
              <p className={cls.title}>{t("UI/UX Excellence")}</p>
              <p className={cls.description}>
                {t("Strategic architecture using microservices and API-first approach, enhancing modularity and scalability. Backend Mastery: Seamless development leveraging Node.js, Django, and Ruby on Rails for robust server-side processing.")}
              </p>
            </div>
            <div className={`${cls.block} ${cls.block5}`}>
              <p className={cls.title}>
                {t("2D & 3D Art & Animations")}
              </p>
              <p className={cls.description}>
                {t("Incorporation of 2D/3D arts & animations for a visually rich web experience, powered by tools like Three.js and GSAP.")}
              </p>
            </div>
            <div className={`${cls.block} ${cls.block6}`}>
              <p className={cls.title}>
                {t("Web platforms development")}
              </p>
              <p className={cls.description}>
                {t("Holistic development across CMS, e-commerce, and PWA using WordPress, Shopify, and React Native Web.")}
              </p>
            </div>
          </div>
        </div>
        <div className={cls.mobileBlock}>
          <NavLink to={"#"}>
            {t("Check the project")}{" "}
            <img src={right} alt="right" width={"8px"} />
          </NavLink>
        </div>
      </div>
    </div>
  );
};
export default WebPage;
