import { useTranslation } from "react-i18next";
import cls from "./TeamPages.module.scss";
import right from "../../assets/icon/right.svg";
import studio1 from "../../assets/img/studio 1.png";
import studio2 from "../../assets/img/studio 2.png";
import studio3 from "../../assets/img/studio 3.png";
import studio4 from "../../assets/img/studio 4.png";
import studio5 from "../../assets/img/studio 5.png";
import studio6 from "../../assets/img/studio 6.png";
import studio7 from "../../assets/img/studio 7.png";
import studio8 from "../../assets/img/studio 8.png";
import studio9 from "../../assets/img/studio 9.png";
import studio10 from "../../assets/img/studio 10.png";
import studio11 from "../../assets/img/studio 11.png";
import { Link } from "react-router-dom";
import Team from "../../component/Team/Team";
import SwiperCostam from "../../component/SwiperCostam";
// 
const TeamPages = () => {
  const { t } = useTranslation();

  return (
      <SwiperCostam quanSection={4} scrol="vertical" direction="vertical" dotNone={false} slidesPerView={1}>
        
        <Team
          mainTitleText={t("Studio")}
          descriptionText={t(
            "We love new challenges and design ideas we never worked on before."
          )}
          linkText={t("Get more info")}
        />
        <div className={`${cls.block} ${cls.block1}`}>
          <div>
            <img src={studio2} className={cls.img2} alt="person" />
            <img src={studio1} className={cls.img1} alt="person" />
          </div>
          <img src={studio1} alt="person" />
          <p>
            {t(
              "We value integrity and, most notably, the NDA. We have a secure system in-house for our remote employees that keeps the source codes and media secured. Upon delivering the project, we share all materials and permanently delete them. Neither the company nor any employees will use any material in their portfolio or any other project."
            )}
          </p>
        </div>
        <div className={`${cls.block} ${cls.block2}`}>
          <div>
            <div className={cls.imgBlock}>
              <img src={studio2} alt="person" />
              <img src={studio3} className={cls.img3} alt="" />
              <img src={studio4} className={cls.img4} alt="" />
            </div>
            <p>
              {t(
                "MintFox managed to grow, educate, recruit and maintain exclusive talents thanks to its motto: 'Challenges are what make life interesting, and overcoming them is what makes life meaningful. As until you dare to take on new challenges you wouldn't discover your full potential."
              )}
            </p>
          </div>
          <img src={studio3} className={cls.img3} alt="" />
          <img src={studio4} className={cls.img4} alt="" />
        </div>
        <div className={`${cls.block} ${cls.block3}`}>
          <div>
            <div className={cls.imgBlock}>
              <img src={studio5} alt="person" />
            </div>
            <div className={cls.imgBlock2}>
              <div>
                <img src={studio5} className={cls.img5} alt="person" />
                <img src={studio7} className={cls.img7} alt="" />
              </div>
              <img src={studio6} className={cls.img6} alt="" />
            </div>
            <p>
              {t(
                "So we dodge requests for simple and copy-paste projects. Instead, we adore middle to high-complexity projects with mechanics and storytelling never experienced before. This is what makes our blood boil, where we shine."
              )}
            </p>
          </div>
          <img src={studio6} className={cls.img6} alt="" />
          <img src={studio7} className={cls.img7} alt="" />
        </div>
        <div className={`${cls.block} ${cls.block4}`}>
          <div className={cls.content}>
            <div className={cls.title}>
              <p>{t("TEAM")}</p>
              <h1>{t("TEAM")}</h1>
            </div>
            <div className={cls.description}>
              <p>
                {t(
                  "We are a team of 62 experts who collaborate with external top talents."
                )}
              </p>
              <Link to="#">
                {t("View More")} <img src={right} alt="right" />
              </Link>
            </div>
          </div>
          <div className={cls.blockLeft}>
            <div className={cls.blockimg1}>
              <img src={studio8} className={cls.img8} alt="person" />
            </div>
            <div className={cls.blockimg2}>
              <img src={studio10} className={cls.img10} alt="person" />
            </div>
          </div>
          <div className={cls.blockRight}>
            <div className={cls.blockimg3}>
              <img src={studio9} className={cls.img9} alt="person" />
            </div>
            <div className={cls.blockimg4}>
              <img src={studio11} className={cls.img11} alt="person" />
            </div>
          </div>
        </div>
        {/* <Team
        mainTitleText={t("SOMETHING")}
        secondTitleText={t("WENT WRONG")}
        descriptionText={t(
          "Your message has not been sent. Please try again later."
        )}
        linkText={t("Try Again")}
      /> */}
      </SwiperCostam>

      
  );
};

export default TeamPages;
