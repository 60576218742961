import cls from "./WebPage.module.scss";
import right from "../../assets/icon/right.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import MainScene from "../../component/MainScense/MainScene";
const Blockchain = () => {
  const { t,i18n } = useTranslation();
  return (
    <div className={`${cls.root} ${i18n.language === "ru" ? cls.ru : ""}`}>
      <div className={cls.content}>
        <div className={cls.model}>
          {/* <MainScene position={[-0, -2, -.1]} canvaPosition={[.7, 0, 1.2]} headY={.3}/> */}
        </div>
        <div className={cls.title}>
          <p>{t("Services")}</p>
          <h1>
            {t("Blockchain")} <span>{t("SOLUTIONS")}</span>
          </h1>
        </div>
        <div className={cls.blocks_description}>
          <div className={cls.flexBlock}>
            <div className={cls.block}>
              <p className={cls.title}>{t("NFTs")}</p>
              <p className={cls.description}>
                {t(
                  "Pioneering digital asset minting, management, and marketplaces using Ethereum and Flow."
                )}
              </p>
            </div>
            <div className={cls.block}>
              <p className={cls.title}>{t("Custom Smart Contracts")}</p>
              <p className={cls.description}>
                {t(
                  "Predictive modeling and spatial analysis to prevent and mitigate disasters, leveraging AI integrations."
                )}
              </p>
            </div>
            <div className={`${cls.block} ${cls.block3}`}>
              <p className={cls.title}>
                {t("IPFS/Cloud Data Storage Solutions")}
              </p>
              <p className={cls.description}>
                {t(
                  "Interactive VR labs and modules that promote experiential learning, tailored for varied curricula."
                )}
              </p>
            </div>
          </div>
          <div className={`${cls.flexBlock} ${cls.flexBlock1}`}>
            <div className={`${cls.block} ${cls.block7}`}>
              <p className={cls.title}>{t("Token Economics")}</p>
              <p className={cls.description}>
                {t(
                  "Cross-platform collaboration tools fostering real-time interaction in a shared virtual space, using tech like Spatial and Oculus."
                )}
              </p>
            </div>
            <div className={`${cls.block} ${cls.block4}`}>
              <p className={cls.title}>{t("Web3 Development")}</p>
              <p className={cls.description}>
                {t(
                  "Cross-platform collaboration tools fostering real-time interaction in a shared virtual space, using tech like Spatial and Oculus."
                )}
              </p>
            </div>
            <div className={`${cls.block} ${cls.block5}`}>
              <p className={cls.title}>
                {t("API's and 3rd party integration")}
              </p>
              <p className={cls.description}>
                {t(
                  "Cutting-edge VR & AR games that redefine user engagement, developed with best-in-class haptic feedback and visuals."
                )}
              </p>
            </div>
            <div className={`${cls.block} ${cls.block6}`}>
              <p className={cls.title}>{t("Play to Earn")}</p>
              <p className={cls.description}>
                {t(
                  "Context-aware guidance using ARKit and ARCore, enhancing real-world tasks with digital overlays and real-time info."
                )}
              </p>
            </div>
          </div>
        </div>
        <div className={cls.mobileBlock}>
          <NavLink to={"#"}>
            {t("Check the project")}{" "}
            <img src={right} alt="right" width={"100%"} height={"auto"} />
          </NavLink>
        </div>
      </div>
    </div>
  );
};
export default Blockchain;
