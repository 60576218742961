// import MainScene from "../../component/MainScense/MainScene";
import { useTranslation } from "react-i18next";
import cls from "./ContactUse.module.scss";
// const MainScene = lazy(() => import("../../component/MainScense/MainScene"));
const ContactUse = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className={`${cls.root} ${i18n.language === "ru" ? cls.ru : ""}`}>
      <div className={cls.model}>
        {/* <Suspense fallback={null}>
          <MainScene
            position={[-0, -2.5, 0.1]}
            canvaPosition={[0.4, 0, 1]}
            headY={0.3}
            width={windowSize.width * 0.8}
            height={windowSize.height * 0.8}
          />
        </Suspense> */}
      </div>
      <div className={cls.content}>
        <div className={cls.text}>
          <p>{t("CONTANCTS")}</p>
          <h1>{t("CONTACT US us into unique cases, high-end")}</h1>
        </div>
        <div className={cls.inputBlocks}>
          <form action="#">
            <label>
              <input type="text" placeholder={t('Name')} />
            </label>
            <label>
              <input type="email" placeholder="E-mail" />
            </label>
            <label>
              <input type="message" placeholder={t("Message")} />
            </label>
            <button>{t("Send")}</button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ContactUse;
