import React, { useCallback, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/zoom";
import right from "../assets/icon/right.svg";
import picture from "../assets/img/Rectangle 9425.png";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Mousewheel, Pagination, Autoplay } from "swiper/modules";
import SwiperCore from "swiper"; // Correct import for Autoplay

SwiperCore.use([Autoplay]); // Activate Autoplay module

const SwiperCostam = ({
  children,
  quanSection = 4,
  scrol = "vertical",
  direction = "horizontal",
  initialActiveIndex = 0,
  slidesPerView = 1.8,
  removeScrol = false,
  flexServices = false,
}) => {
  const containerRef = useRef(null);
  const sectionRefs = useRef(new Array(quanSection).fill(null));
  const { t, i18n } = useTranslation();

  const handleScroll = useCallback(
    (event) => {
      if (event) {
        const delta =
          event.deltaY || event.deltaX || event.detail || event.wheelDelta;
        if (Math.abs(delta) > 200) {
          const slidesPerScroll =
            delta > 100 ? (removeScrol ? -1 : 1) : removeScrol ? 1 : -1;
          containerRef.current.swiper.slideTo(
            containerRef.current.swiper.activeIndex + slidesPerScroll
          );
        }
      }
    },
    [removeScrol]
  );

  const handleOnSwiper = useCallback((swiper) => {
    containerRef.current = swiper; // Set reference to Swiper instance
  }, []);

  useEffect(() => {
    // Delay setting the Swiper reference
    const timeout = setTimeout(() => {
      handleOnSwiper(containerRef.current);
    }, 400); // Delay for 0.4 seconds

    return () => clearTimeout(timeout); // Clean up timeout on component unmount
  }, [handleOnSwiper]);
  return (
    <Swiper
      direction={scrol}
      slidesPerView={1}
      spaceBetween={0}
      mousewheel={{ invert: true, speed: 5000 }}
      pagination={{ clickable: true }}
      className={`mySwiper ${i18n.language === "ru" ? "ru" : ""}`}
      onSwiper={(swiper) => handleOnSwiper(swiper)}
      onWheel={handleScroll} // Mousewheel event handler for scrolling
      centeredSlides={true}
      autoplay={{
        delay: 5000, // Autoplay delay in milliseconds
        disableOnInteraction: false,
      }}
      speed={5000} // Slide transition speed in milliseconds
      modules={[Autoplay,Pagination]}
    >
      <SwiperSlide>
        <Swiper
          direction={direction}
          slidesPerView={slidesPerView}
          centeredSlides={true}
          centerInsufficientSlides={true}
          spaceBetween={100}
          mousewheel={{
            invert: true,
            eventsTarget: "container",
            releaseOnEdges: true,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Mousewheel, Pagination]}
          className="sectionsContainer"
          initialSlide={initialActiveIndex}
        >
          {children
            ? React.Children.map(children, (child, i) => (
                <SwiperSlide
                  key={`section-${i}`}
                  virtualIndex={i} // Use virtualIndex to get indices
                  className={`section ${flexServices ? "flexSection" : ""}`}
                >
                  <section ref={(el) => (sectionRefs.current[i] = el)}>
                    {child}
                  </section>
                </SwiperSlide>
              ))
            : Array(4)
                .fill()
                .map((_, i) => (
                  <SwiperSlide key={`section-${i}`} className={`section`}>
                    <div className={"content"}>
                      <div className={"picture"}>
                        <img src={picture} alt="pictur" />
                      </div>
                      <div className={"text"}>
                        <div className={"number"}>
                          <p>0{i + 1}</p>
                        </div>
                        <div className={"description"}>
                          <h5 className={"title"}>
                            {t("Swiper Project name")}
                          </h5>
                          <p className={"info"}>
                            {t("Swiper Project name Descraption")}
                          </p>
                          <Link className="linkSwiper">
                            {t("Contact Us")}{" "}
                            <img width={"14px"} src={right} alt="right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
        </Swiper>
      </SwiperSlide>
    </Swiper>
  );
};

export default SwiperCostam;
