import { Link } from "react-router-dom";
import cls from "./Team.module.scss";
import right from "../../assets/icon/right.svg";
import { useTranslation } from "react-i18next";
const Team = ({
  mainTitleText,
  secontTitleText,
  descriptionText,
  linkText,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={`${cls.root} ${i18n.language === "ru" ? cls.ru : ""}`}>
      {/* <div className={cls.model}> */}
        {/* <Suspense fallback={null}>
          <MainScene
            position={[-0, -2.5, 0.1]}
            canvaPosition={[0.4, 0, 1]}
            headY={0.3}
          />
        </Suspense> */}
      {/* </div> */}
      <div className={cls.content}>
        <div className={cls.title}>
          <p>{t("TEAM")}</p>
          <h1>
            {mainTitleText}
            <span>{secontTitleText}</span>
          </h1>
        </div>
        <div className={cls.description}>
          <p>{descriptionText}</p>
          <Link to="#">
            {linkText} <img src={right} alt="right" />
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Team;
