import cls from "./WebPage.module.scss";
import right from "../../assets/icon/right.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import MainScene from "../../component/MainScense/MainScene";
const Mobile = () => {
  const { t,i18n } = useTranslation();
  return (
    <div className={`${cls.root} ${i18n.language === "ru" ? cls.ru : ""}`}>
      <div className={cls.content}>
        {/* <div className={cls.model}>
        <MainScene position={[-0, -2, -.1]} canvaPosition={[.7, 0, 1.2]} headY={.3}/>
      </div> */}
        <div className={cls.title}>
          <p>{t("Services")}</p>
          <h1>
            {t("MOBILE APP")} <span>{t("DEVELOPMENT")}</span>
          </h1>
        </div>
        <div className={cls.blocks_description}>
          <div className={cls.flexBlock}>
            <div className={cls.block}>
              <p className={cls.title}>{t("Design Mastery")}</p>
              <p className={cls.description}>
                {t("Crafting captivating visuals and intuitive interfaces, using tools like Sketch.")}
              </p>
            </div>
            <div className={cls.block}>
              <p className={cls.title}>{t("Prototyping")}</p>
              <p className={cls.description}>
                {t("Real-time interactive mockups and user testing via InVision and Marvel, ensuring product-market fit.")}
              </p>
            </div>
          </div>
          <div className={`${cls.flexBlock} ${cls.flexBlock1}`}>
            <div className={`${cls.block} ${cls.block4}`}>
              <p className={cls.title}>{t("Native Evolution")}: </p>
              <p className={cls.description}>
                {t("Advanced development on iOS (Swift, Objective-C) and Android (Kotlin, Java) for optimized performance and platform-specific advantages.")}
              </p>
            </div>
            <div className={`${cls.block} ${cls.block5}`}>
              <p className={cls.title}>{t("Support & Maintenance")}</p>
              <p className={cls.description}>
                {t("Continuous support and maintenance post-deployment, utilizing analytics, feedback loops, and OTA updates to ensure peak app health.")}
              </p>
            </div>
          </div>
        </div>
        <div className={cls.mobileBlock}>
          <NavLink to={"#"}>
            {t("Check the project")}{" "}
            <img src={right} alt="right" width={"8px"} />
          </NavLink>
        </div>
      </div>
    </div>
  );
};
export default Mobile;
