import { useLocation } from "react-router-dom";
import Blockchain from "../../WebPage/BlockChain";
import Mobile from "../../WebPage/Mobile";
import Smart from "../../WebPage/Smart";
import WebPage from "../../WebPage/WebPage";
import cls from "./Servises.modeule.scss";
import SwiperCostam from "../../../component/SwiperCostam";

const Services = () => {
  const location = useLocation();
  const { activePage = 0 } = location.state || {};

  return (
    <div className={cls.root}>
      <SwiperCostam
        quanSection={4}
        scrol="horizontal"
        removeScrol={true}
        direction="horizontal"
        initialActiveIndex={activePage}
        slidesPerView={1.3}
        flexServices={true}
      >
        {/* <Game/> */}
        <WebPage />
        {/* <ARVR /> */}
        <Blockchain /> 
        <Mobile />
        <Smart />
      </SwiperCostam>
    </div>
  );
};

export default Services;
