import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Импортируйте Link из react-router-dom, если он используется
import cls from "./Footer.module.scss"; // Путь к модульным стилям Footer

// Импортируем изображения с учетом пути относительно директории src
import faceBoock from "../../assets/icon/Faceboock.svg";
import linkedin from "../../assets/icon/linkedin.svg";
import discord from "../../assets/icon/discord.svg";

const Footer = () => {
  const [isScrolledDown, setIsScrolledDown] = useState(true);
  // const [isSaund, setIsSaund] = useState(false);

  // const toggleSaund = () => {
  //   setIsSaund(!isSaund);
  // };
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop >= 0 && !isScrolledDown) {
        setIsScrolledDown(true);
      } else if (scrollTop === 0 && isScrolledDown) {
        setIsScrolledDown(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolledDown]);

  return (
    <div
      className={`${cls.root} ${
        isScrolledDown ? cls.scrolledDown : cls.scrolledTop
      }`}
    >
      <div className={cls.network}>
        <Link to="#">
          <img src={faceBoock} alt="Facebook" />
        </Link>
        <Link to="#">
          <img src={linkedin} alt="LinkedIn" />
        </Link>
        <Link to="#">
          <img src={discord} alt="Discord" />
        </Link>
      </div>
      {/* <div
        className={`${cls.saundBlock} ${isSaund ? cls.saund : ""}`}
        onClick={toggleSaund}
      >
        <span className={`${cls.line} ${cls.line1}`}></span>
        <span className={`${cls.line} ${cls.line2}`}></span>
        <span className={`${cls.line} ${cls.line3}`}></span>
        <span className={`${cls.line} ${cls.line4}`}></span>
        <span className={`${cls.line} ${cls.line5}`}></span>
        <span className={`${cls.line} ${cls.line6}`}></span>
      </div> */}
    </div>
  );
};

export default Footer;
