import { NavLink } from "react-router-dom";
import cls from "./WhatWeDo.module.scss";
import right from "../../assets/icon/right-black.svg";
import { useTranslation } from "react-i18next";

const WhatWeDo = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className={`${cls.root} ${i18n.language === "ru" ? cls.ru : ""}`}>
      <div>
        <p>{t("What we Do")}</p>
        <ul>
          {/* <li>
            <NavLink to={"/Services"} state={{ activePage: 0 }}>
              {t("GAME")}{" "}
              <span className={cls.strikText}>{t("DEVELOPMENT")}</span>
              <span className={cls.hover_text}>
              {t("WhatWeDoDiscraptopn")}
              </span>
              <img src={right} alt="right" />
            </NavLink>
          </li> */}
          <li>
            <NavLink to={"/services"} state={{ activePage: 0 }}>
              {t("WEB")}{" "}
              <span className={cls.strikText}>{t("DEVELOPMENT")}</span>
              <span className={cls.hover_text}>
              {t("WhatWeDoDiscraptopn")}
              </span>
              <img src={right} alt="right" />
            </NavLink>
          </li>
          {/* <li>
            <NavLink to={"/Services"} state={{ activePage: 2 }}>
              {t("AR & VR")}{" "}
              <span className={cls.strikText}>{t("MIXED REALITY")}</span>
              <span className={cls.hover_text}>
              {t("WhatWeDoDiscraptopn")}
              </span>
              <img src={right} alt="right" color="black" />
            </NavLink>
          </li> */}
          {/*  */}
          <li>
            <NavLink to={"/services"} state={{ activePage: 1 }}>
              {t("BLOCKCHAIN")}{" "}
              <span className={cls.strikText}>{t("SOLUTIONS")}</span>
              <span className={cls.hover_text}>{t("WhatWeDoDiscraptopn")}</span>
              <img src={right} alt="right" />
            </NavLink>
          </li>
          <li>
            <NavLink to={"/services"} state={{ activePage: 2 }}>
              {t("MOBILE APP")}{" "}
              <span className={cls.strikText}>{t("DEVELOPMENT")}</span>
              <span className={cls.hover_text}>
              {t("WhatWeDoDiscraptopn")}
              </span>
              <img src={right} alt="right" />
            </NavLink>
          </li>
          <li>
            <NavLink to={"/services"} state={{ activePage: 3 }}>
              {t("SMART")}{" "}
              <span className={cls.strikText}>{t("CONTRACTS")}</span>
              <span className={cls.hover_text}>
              {t("WhatWeDoDiscraptopn")}
              </span>
              <img src={right} alt="right" />
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WhatWeDo;
